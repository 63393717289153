const tintColorLight = '#2f95dc'
const tintColorDark = '#fff'

export default {
  oxfordBlue: '#14213D',
  orangeWeb: '#FCA311',
  platinum: '#E5E5E5',
  darkPlatinum: '#CECECE',
  white: '#FFFFFF',
  unselectedBlue: '#7582A3',
  transparentOxfordBlue: 'rgba(20, 33, 61, 0.8)',
  listWhite: '#FBFBF8',
}
