import { omitEmpty } from '../../utils'
import { IArticleQuery } from './articles.types'
import { client } from '..'

export interface IFetchArticlesParams {
  Offset: number
  Limit: number
  ContentTag?: string
  SourceTag?: string
  SearchText?: string
  CreatedBefore?: string
  ArticleIds?: Array<string>
}

export async function fetchArticles(params: IFetchArticlesParams) {
  const {
    Offset,
    Limit,
    ContentTag,
    SourceTag,
    SearchText,
    CreatedBefore,
    ArticleIds,
  } = params
  try {
    const response = await client.get<IArticleQuery>('/api/articles', {
      params: omitEmpty({
        Offset,
        Limit,
        ContentTag,
        SourceTag,
        SearchText,
        CreatedBefore,
        ArticleIds,
      }),
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}
