import React, { FC, useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { Card, Image } from 'react-native-elements'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { Text, View } from '../components/Themed'
import Browser from '../constants/Browser'
import Colors from '../constants/Colors'
import JSON from '../package.json'

export const SuccessCard: FC = () => {
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.title}>
        <Icon
          name="check-circle-outline"
          size={24}
          style={styles.successIcon}
        />
        <Text>Sikeres Telepítés!</Text>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>
          Sikeresen telepítetted az alkalmazást, kérjük nyisd meg azt, majd
          aktiváld felhasználódat!
        </Text>
        <Text style={styles.text}>
          Amennyiben további kérdésed maradt lépj kapcsolatba
          ügyfélszolgálatunkkal!
        </Text>
      </View>
    </Card>
  )
}

export const FailureCard: FC = () => {
  const browser = Browser.isIOS ? 'Safari' : 'Chrome'
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.head}>
        <Icon name="error-outline" size={24} style={styles.failureIcon} />
        <Text style={styles.title}>Sikertelen telepítés!</Text>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>
          Úgy tűnik akadályba ütköztél az alkalmazás telepítése során. Ennek
          több oka is lehet:
        </Text>
        <View style={styles.list}>
          <Text
            style={styles.listItem}
          >{`\u2022 Az alkalmazás már telepítve van az eszközödön`}</Text>
          <Text
            style={styles.listItem}
          >{`\u2022 Az alkalmazás nem megfelelő környezetből lett megnyitva`}</Text>
          <Text
            style={styles.listItem}
          >{`\u2022 A böngésződ nem támogatja az alkalmazás letöltését`}</Text>
        </View>
        <Text style={styles.text}>
          Kérjük próbáld újra a "{browser}" böngésző használatával
        </Text>
        <Text style={styles.text}>
          Amennyiben további kérdésed maradt lépj kapcsolatba
          ügyfélszolgálatunkkal!
        </Text>
      </View>
    </Card>
  )
}

export const WaitCard: FC = () => {
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.title}>
        <ActivityIndicator
          color={Colors.orangeWeb}
          style={styles.warningIcon}
        />
        <Text>Kérjük várjon...</Text>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>
          A telepítési képernyő pillanatokon belül meg kell, hogy jelenjen.
        </Text>
        <Text style={styles.text}>
          Amennyiben mégsem jelenne meg, valószínűleg az alkalmazás már
          telepítve van eszközödön.
        </Text>
        <Text style={styles.text}>
          Amennyiben további kérdésed maradt lépj kapcsolatba
          ügyfélszolgálatunkkal!
        </Text>
      </View>
    </Card>
  )
}

export const SafariHowToCard: FC = () => {
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.title}>
        <View style={styles.head}>
          <Icon name="warning" size={24} style={styles.warningIcon} />
          <View style={styles.headText}>
            <Text style={styles.title}>Útmutató</Text>
            <Text style={styles.subtitle}>{`v${JSON.version}`}</Text>
          </View>
        </View>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>
          Úgy tűnik iOS környezetből nyitottad meg rendszerünket, így az
          alkalmazás csak manuálisan telepíthető.
        </Text>
        <View style={styles.list}>
          <Text style={styles.listItem}>
            {`\u2022 Kattints a "Megosztás"  `}
            <Image
              placeholderStyle={styles.imagePlaceholder}
              containerStyle={styles.imageContainer}
              style={styles.image}
              resizeMode={'stretch'}
              source={{
                uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC44OCAyNy4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMyMzFmMjA7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5TYWZhcmlfU2hhcmU8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTMuMTMgOCAyMC4zOCA4IDIwLjM4IDI2Ljc1IDAuNSAyNi43NSAwLjUgOCA3LjUgOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMTciIHgyPSIxMC40NCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ4IiB5MT0iMC4zOCIgeDI9IjE1LjI4IiB5Mj0iNS4xOCIvPjxsaW5lIGNsYXNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMC4zOCIgeDI9IjUuNjQiIHkyPSI1LjE4Ii8+PC9zdmc+`,
              }}
            />
            {` gombra Safari böngésződ ${Browser.whereIsShare}`}
          </Text>
          <Text style={styles.listItem}>
            {`\u2022 Görgess (amennyiben szükséges) amíg meg nem találod a "Főképernyőhöz adás" `}
            <Image
              placeholderStyle={styles.imagePlaceholder}
              containerStyle={styles.imageContainer}
              style={styles.image}
              resizeMode={'stretch'}
              source={{
                uri: `data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHRpdGxlPlNhZmFyaV9BMkhTPC90aXRsZT48cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHJ4PSI4IiByeT0iOCIgc3R5bGU9ImZpbGw6IzhmOGY4ZiIvPjxsaW5lIHgxPSIyNC43NSIgeTE9IjE3LjUiIHgyPSIxMC4yNSIgeTI9IjE3LjUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjxsaW5lIHgxPSIxNy41IiB5MT0iMTAuMjUiIHgyPSIxNy41IiB5Mj0iMjQuNzUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjwvc3ZnPg==`,
              }}
            />
            {` gombot`}
          </Text>
        </View>
        <Text style={styles.text}>
          Amennyiben további kérdésed maradt lépj kapcsolatba
          ügyfélszolgálatunkkal!
        </Text>
      </View>
    </Card>
  )
}

export default function InstallScreen() {
  const [isInstalled, setIsInstalled] = useState(false)
  window.addEventListener('appinstalled', () => {
    setIsInstalled(true)
  })
  return (
    <View style={styles.container}>
      {(() => {
        if (isInstalled) {
          return <SuccessCard />
        } else if (Browser.showIOSSafariHow2) {
          return <SafariHowToCard />
        } else if (!Browser.isSupportingBrowser) {
          return <FailureCard />
        } else {
          return <WaitCard />
        }
      })()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: 'gray',
  },
  fullView: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  card: {
    width: '80%',
    borderRadius: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    color: 'gray',
    marginBottom: 5,
  },
  list: {
    marginVertical: 10,
  },
  listItem: {
    marginBottom: 3,
    marginLeft: 10,
    fontSize: 14,
    color: 'gray',
  },
  successIcon: {
    color: '#7DE24E',
    marginRight: 5,
  },
  failureIcon: {
    color: '#FF0000',
    marginRight: 5,
  },
  warningIcon: {
    color: Colors.orangeWeb,
    marginRight: 5,
  },
  imagePlaceholder: { backgroundColor: 'transparent' },
  imageContainer: {},
  image: { height: 16, width: 16 },

  head: {
    marginTop: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headText: { marginLeft: 10, display: 'flex', alignItems: 'flex-start' },
  subtitle: {
    marginLeft: 5,
    color: 'gray',
    fontSize: 16,
    fontWeight: 'normal',
  },
})
