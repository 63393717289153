import React, {
  Dispatch,
  FC,
  SetStateAction,
  useLayoutEffect,
  useState,
} from 'react'

export interface IScreenSize {
  width: number
  height: number
}

const ScreenSizeContext = React.createContext<IScreenSize>({
  width: window.innerWidth,
  height: window.innerHeight,
})
export interface IScreenSizeProps {
  children?: JSX.Element
}

export const ScreenSizeProvider: FC<IScreenSizeProps> = (props) => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [height, setHeight] = useState<number>(window.innerHeight)
  const resizeListener = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', resizeListener)
    return () => window.removeEventListener('resize', resizeListener)
  }, [])

  return (
    <ScreenSizeContext.Provider value={{ width, height }}>
      {props.children}
    </ScreenSizeContext.Provider>
  )
}

export const ScreenSizeConsumer = ScreenSizeContext.Consumer
