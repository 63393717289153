import Browser from './Browser'
import { isProduction, onesignalAppId } from './Config'

class Onesignal {
  private startupListeners: Array<() => void> = []

  private popoverListeners: Array<(isAllowed: boolean) => void> = []

  private changeListeners: Array<(playerId: string) => void> = []

  constructor() {
    window.OneSignal = window.OneSignal || []
    if (!isProduction || Browser.isIOS) return

    window.OneSignal.push(async () => {
      await window.OneSignal.init({
        appId: onesignalAppId,
      })

      for (let cb of this.startupListeners) cb()
    })

    window.OneSignal.push(() => {
      window.OneSignal.on('popoverAllowClick', async () => {
        console.log('Slide Prompt Allow is clicked by the user')
        for (let cb of this.popoverListeners) cb(true)
      })
    })

    window.OneSignal.push(() => {
      window.OneSignal.on('popoverCancelClick', async () => {
        console.log('Slide Prompt Cancel is clicked by the user')
        for (let cb of this.popoverListeners) cb(false)
      })
    })

    window.OneSignal.push(() => {
      window.OneSignal.on('subscriptionChange', async () => {
        console.log('Subscription type change event is fired')
        const playerId = await onesignal.getPlayerId()
        for (let cb of this.changeListeners) cb(playerId)
      })
    })
  }

  registerOnInitHandler(cb: () => void): void {
    this.startupListeners.push(cb)
  }

  registerOnPopoverHandler(cb: (isAllowed: boolean) => void): void {
    this.popoverListeners.push(cb)
  }

  registerOnChangeHandler(cb: (playerId: string) => void): void {
    this.changeListeners.push(cb)
  }

  async getPlayerId(): Promise<string> {
    if (!isProduction || !window.OneSignal?.initialized) return ''
    return window.OneSignal.getUserId()
  }

  async rebuild(): Promise<void> {
    if (!isProduction || !window.OneSignal?.initialized) return
    localStorage.removeItem('onesignal-notification-prompt')
    await window.OneSignal.database.rebuild()
    await window.OneSignal.database.setAppConfig({ appId: onesignalAppId })
    window.OneSignal.showSlidedownPrompt()
  }

  async isEnabled(): Promise<boolean> {
    if (!isProduction || !window.OneSignal?.initialized) return false
    return window.OneSignal.isPushNotificationsEnabled()
  }
}

export const onesignal = new Onesignal()
