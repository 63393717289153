import { IUser } from '../../api/user'
import { authTokenPath, refreshTokenPath } from '../../constants/Config'

export interface IUserState {
  isLoading: boolean
  user: IUser
  token?: string
  refreshToken?: string
  error?: string
}

export const initialUserState: IUserState = {
  user: {
    Id: '',
    Name: '',
    Email: '',
    Phone: '',
    PreferredTags: [],
    PreferredLanguage: 'default',
    IsSnooze: false,
    SnoozeTime: '',
    BookmarkedNews: [],
    BookmarkedAnalyses: [],
    DidUnsubscribe: false,
    OneSignalSubscribed: null,
  },
  isLoading: false,
  token: localStorage.getItem(authTokenPath) || '',
  refreshToken: localStorage.getItem(refreshTokenPath) || '',
}
