import React, { useEffect, useState } from 'react'
import { onesignal } from '../constants/Onesignal'

export interface IOnesignalContext {
  isPushEnabled: boolean | null
}

export const OnesignalContext = React.createContext<IOnesignalContext>({
  isPushEnabled: null,
})

export interface IOnesignalProps {
  children?: JSX.Element
}

export const OnesignalProvider = (props: IOnesignalProps) => {
  const [isPushEnabled, setIsPushEnabled] = useState<boolean | null>(null)

  useEffect(() => {
    onesignal.registerOnPopoverHandler((enabled) => setIsPushEnabled(enabled))
  }, [])

  return (
    <OnesignalContext.Provider value={{ isPushEnabled }}>
      {props.children}
    </OnesignalContext.Provider>
  )
}

export const OnesignalConsumer = OnesignalContext.Consumer
