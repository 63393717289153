import { StatusBar } from 'expo-status-bar'
import React, { useEffect, useState } from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import Toast from 'react-native-toast-message'
import AppLoading from 'expo-app-loading'

import useCachedResources from './hooks/useCachedResources'
import Navigation from './navigation'
import { register } from './workers/serviceWorkerRegistration'
import { InstallPrompt } from './components/InstallPrompt'
import InstallScreen from './screens/InstallScreen'
import JSON from './package.json'
import { UserProvider } from './context'
import {
  deviceIdPath,
  deviceNamePath,
  isProduction,
  versionPath,
} from './constants/Config'
import Browser, { BrowserType } from './constants/Browser'
import { GoogleTracker } from './constants/GoogleTracker'
import { ScreenSizeProvider } from './context/screen-size.context'
import { getDeviceId } from './utils'

register()

export default function App() {
  const isLoadingComplete = useCachedResources()
  const [isInstalled, setIsInstalled] = useState(false)
  useEffect(() => {
    const oldVersion = localStorage.getItem(versionPath)
    const newVersion = JSON.version
    if (!isInstalled || oldVersion === newVersion) return
    localStorage.setItem(versionPath, newVersion)
    Toast.show({
      type: 'info',
      text1: `Új verzió! (v${newVersion})`,
      text2: 'Az alkalmazást a háttérben frissítettük.',
      visibilityTime: 6000,
    })
  }, [isInstalled])

  useEffect(() => {
    ;(async () => {
      const deviceId = await getDeviceId()
      const id = localStorage.getItem(deviceIdPath) || deviceId
      const name = localStorage.getItem(deviceNamePath) || navigator.userAgent
      localStorage.setItem(deviceIdPath, id)
      localStorage.setItem(deviceNamePath, name)
    })()

    const media = window.matchMedia('(display-mode: standalone)')
    /** @note change this to test install screens */
    const getIsInstalled = () =>
      media.matches ||
      (Browser.browser === BrowserType.Firefox && Browser.isMobile) ||
      !isProduction
    const listener = () => setIsInstalled(getIsInstalled())
    setIsInstalled(getIsInstalled())
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [])

  useEffect(() => {
    const tracker = new GoogleTracker()
    tracker.start()
  }, [])
  return (
    <>
      {(() => {
        if (!isLoadingComplete) {
          return <AppLoading />
        } else {
          return (
            <ScreenSizeProvider>
              <UserProvider>
                <SafeAreaProvider>
                  <InstallPrompt />
                  {isInstalled ? <Navigation /> : <InstallScreen />}
                  <StatusBar />
                </SafeAreaProvider>
              </UserProvider>
            </ScreenSizeProvider>
          )
        }
      })()}
      <Toast />
    </>
  )
}
