// Example of Splash, Login and Sign Up in React Native
// https://aboutreact.com/react-native-login-and-signup/
import 'react-native-gesture-handler'

// Import React and Component
import React, { FunctionComponent, useEffect } from 'react'

// Import Navigators from React Navigation
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { StyleSheet, SafeAreaView } from 'react-native'
import LinkingConfiguration from './LinkingConfiguration'
import Colors from '../constants/Colors'
import { LoginScreen } from '../screens/LoginScreen'
import { RootNavigator } from './RootNavigator'
import { SettingsScreen } from '../screens/SettingsScreen'
import { HelpScreen } from '../screens/HelpScreen'
import { NotFoundScreen } from '../screens/NotFoundScreen'
import { CategoriesScreen } from '../screens/CategoriesScreen'
import { PreferredLanguageScreen } from '../screens/PreferredLanguageScreen'
import {
  ContentTagsProvider,
  SentimentTagsProvider,
  SourceTagsProvider,
  UserConsumer,
} from '../context'
import { PreferredTagsProvider } from '../context/preferred-tags.context'
import { NotificationScreen } from '../screens/NotificationScreen'
import { OnesignalProvider } from '../context/onesignal.context'
import { PDFReaderScreen } from '../screens/PDFReaderScreen'
import { AnalysisRootNavigator } from './AnalysisRootNavigator'
import { ScreenSizeConsumer } from '../context/screen-size.context'
import { HeaderComponent } from '../components/HeaderComponent'

const Stack = createNativeStackNavigator()

export interface IAuthNavigationProps {}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: Colors.oxfordBlue,
  },
})

export const AuthNavigation: FunctionComponent<IAuthNavigationProps> = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js'
    // script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <OnesignalProvider>
      <UserConsumer>
        {({ token }) => (
          <SourceTagsProvider token={token}>
            <SentimentTagsProvider token={token}>
              <ContentTagsProvider token={token}>
                <PreferredTagsProvider>
                  <ScreenSizeConsumer>
                    {({ height }) => (
                      <SafeAreaView
                        style={[styles.safeArea, { maxHeight: height }]}
                      >
                        <NavigationContainer
                          linking={LinkingConfiguration}
                          theme={DefaultTheme}
                        >
                          <Stack.Navigator
                            screenOptions={{
                              header: (props) => <HeaderComponent {...props} />,
                            }}
                          >
                            {!token ? (
                              <Stack.Screen
                                name="Login"
                                component={LoginScreen}
                                options={{ headerShown: false }}
                              />
                            ) : (
                              <>
                                <Stack.Screen
                                  name="Home"
                                  component={RootNavigator}
                                  options={{ headerShown: false }}
                                />
                                <Stack.Screen
                                  name="Analysis"
                                  component={AnalysisRootNavigator}
                                  options={{ headerShown: false }}
                                />
                                <Stack.Screen
                                  name="PDFReader"
                                  component={PDFReaderScreen}
                                  options={{ headerTitle: 'Elemzés' }}
                                />
                                <Stack.Screen
                                  name="Settings"
                                  component={SettingsScreen}
                                  options={{ headerTitle: 'Beállítások' }}
                                />
                                <Stack.Screen
                                  name="Categories"
                                  component={CategoriesScreen}
                                  options={{ headerTitle: 'Kategóriák' }}
                                />
                                <Stack.Screen
                                  name="PreferredLanguage"
                                  component={PreferredLanguageScreen}
                                  options={{
                                    headerTitle: 'Cikkek elsődleges nyelve',
                                  }}
                                />
                                <Stack.Screen
                                  name="Notification"
                                  component={NotificationScreen}
                                  options={{
                                    headerTitle: 'Értesítések',
                                  }}
                                />
                                <Stack.Screen
                                  name="Help"
                                  component={HelpScreen}
                                  options={{ headerTitle: 'Segítség' }}
                                />
                                <Stack.Screen
                                  name="NotFound"
                                  options={{ headerShown: false }}
                                  component={NotFoundScreen}
                                />
                              </>
                            )}
                          </Stack.Navigator>
                        </NavigationContainer>
                      </SafeAreaView>
                    )}
                  </ScreenSizeConsumer>
                </PreferredTagsProvider>
              </ContentTagsProvider>
            </SentimentTagsProvider>
          </SourceTagsProvider>
        )}
      </UserConsumer>
    </OnesignalProvider>
  )
}
