import { IUserQuery, ILoginMutation, ILogoutMutation } from './user.types'
import { client } from '..'
import Browser from '../../constants/Browser'
import { deviceIdPath, deviceNamePath } from '../../constants/Config'
import { onesignal } from '../../constants/Onesignal'
import { omitEmpty } from '../../utils'

export async function fetchProfile() {
  let playerId = ''
  try {
    playerId = await onesignal.getPlayerId()
  } catch (e) {
    console.error(e)
  }
  try {
    const response = await client.get<IUserQuery>('/api/profile', {
      params: omitEmpty({ playerId }),
    })
    return {
      ...response.data,
      PreferredTags: (response.data.PreferredTags || []).filter((t) => !!t),
      BookmarkedNews: (response.data.BookmarkedNews || []).filter((t) => !!t),
      playerId,
      BookmarkedAnalyses: (response.data.BookmarkedAnalyses || []).filter(
        (t) => !!t
      ),
    }
  } catch (error) {
    console.error(error)
  }
}

export interface ILoginParams {
  Email: string
  ActivationCode: string
}

export async function login(params: ILoginParams) {
  const deviceId = localStorage.getItem(deviceIdPath)
  const deviceName = localStorage.getItem(deviceNamePath)
  let playerId = ''
  try {
    playerId = await onesignal.getPlayerId()
  } catch (e) {
    console.error(e)
  }
  const response = await client.post<ILoginMutation>('/api/login', {
    username: params.Email,
    password: params.ActivationCode,
    isIOS: `${Browser.isIOS}`,
    deviceName,
    deviceId,
    playerId,
  })
  if (response.data?.Error) throw new Error(response.data.Error)
  if (response.data?.error) throw new Error(response.data.error)

  return response.data
}

export async function refreshToken(refreshtoken: string) {
  const response = await client.post<ILoginMutation>('/api/refreshtoken', {
    refreshtoken,
  })
  return response.data
}

export async function logout() {
  const deviceId = localStorage.getItem(deviceIdPath)
  let playerId = ''
  try {
    playerId = await onesignal.getPlayerId()
  } catch (e) {
    console.error(e)
  }
  const response = await client.post<ILogoutMutation>('/api/logout', {
    deviceId,
    playerId,
  })
  if (response.data?.Error) throw new Error(response.data.Error)
  if (response.data?.error) throw new Error(response.data.error)
  return response.data
}

export interface IUpdateProfileParams {
  PreferredTags?: Array<string>
  PreferredLanguage?: string
  BookmarkedNews?: Array<string>
  BookmarkedAnalyses?: Array<string>
}

export async function updateProfile(params: IUpdateProfileParams) {
  let playerId = ''
  try {
    playerId = await onesignal.getPlayerId()
  } catch (e) {
    console.error(e)
  }
  return client.put<ILoginMutation>('/api/profile', { ...params, playerId })
}

export interface IUpdateSnoozeParams {
  SnoozeTime?: string
}

export async function updateSnooze(params: IUpdateSnoozeParams) {
  try {
    await client.put<ILoginMutation>('/api/snooze', params)
  } catch (error) {
    console.error(error)
  }
}

export interface IUpdateUnsubscribeParams {
  DidUnsubscribe?: boolean
}

export async function updateUnsubscribe(params: IUpdateUnsubscribeParams) {
  try {
    await client.post<ILoginMutation>('/api/unsubscribe', params)
  } catch (error) {
    console.error(error)
  }
}
