import React, { FunctionComponent } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import Colors from '../constants/Colors'

export interface INavbarIcon {
  name: string
  direction: string
}

export interface INavbarIconButtonProps {
  navigation: any
  active: string
  side: keyof typeof navbarIcons
}

export const navbarIcons: { left: INavbarIcon[]; right: INavbarIcon[] } = {
  left: [
    {
      name: 'article',
      direction: 'Home',
    },
    {
      name: 'query_stats',
      direction: 'Analysis',
    },
  ],
  right: [
    {
      name: 'settings',
      direction: 'Settings',
    },
  ],
}

const styles = StyleSheet.create({
  iconWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  left: { justifyContent: 'flex-start', marginLeft: 7 },
  right: { justifyContent: 'flex-end', marginRight: 7 },
})

export const NavbarIconButton: FunctionComponent<INavbarIconButtonProps> = (
  props
) => {
  const { navigation, active, side } = props
  return (
    <View style={[styles.iconWrapper, styles[side]]}>
      {navbarIcons[side].map((icon, i) => (
        <Pressable key={i} onPress={() => navigation.navigate(icon.direction)}>
          <span
            className="material-icons"
            style={{
              color:
                active === icon.direction
                  ? Colors.orangeWeb
                  : Colors.unselectedBlue,
              fontSize: '30px',
              marginRight: '5px',
              marginLeft: '5px',
            }}
          >
            {icon.name}
          </span>
        </Pressable>
      ))}
    </View>
  )
}
