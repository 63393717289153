import React, { FC, useEffect, useReducer, useState } from 'react'
import {
  initialArticleState,
  IArticlesState,
  articleReducer,
} from '../state/articles'
import {
  useFetchArticles,
  useRefreshArticles,
} from '../state/articles/articles.creators'

interface ArticlesContextProps extends IArticlesState {
  refresh: () => void
  setCount: (count: number) => void
}
const initialState = {
  ...initialArticleState,
  refresh: () => {},
  setCount: () => {},
}

const ArticlesContext = React.createContext<ArticlesContextProps>(initialState)

export interface IArticlesProviderProps {
  ContentTag?: string
  SourceTag?: string
  SearchText?: string
  CreatedBefore?: string
  ArticleIds?: Array<string>
}

export const ArticlesProvider: FC<IArticlesProviderProps> = (props) => {
  const Limit = 10
  const [Offset, setOffset] = useState(0)
  const { SourceTag, ContentTag, SearchText, ArticleIds, CreatedBefore } = props
  const [data, dispatch] = useReducer(articleReducer, initialArticleState)
  const fetchArticles = useFetchArticles(dispatch)
  const refreshArticles = useRefreshArticles(dispatch)
  const setCount = (count: number) => {
    setOffset(count)
  }
  const refresh = async () => {
    if (!CreatedBefore) return
    setCount(0)
    await refreshArticles({
      Offset: 0,
      Limit,
      SourceTag,
      ContentTag,
      SearchText,
      CreatedBefore,
      ArticleIds,
    })
  }

  useEffect(() => {
    if (Offset === 0) return
    fetchArticles({
      Offset,
      Limit,
      SourceTag,
      ContentTag,
      SearchText,
      CreatedBefore,
      ArticleIds,
    })
  }, [Offset, Limit])

  useEffect(() => {
    refresh()
  }, [SourceTag, SearchText, ArticleIds, CreatedBefore])

  return (
    <ArticlesContext.Provider value={{ ...data, refresh, setCount }}>
      {props.children}
    </ArticlesContext.Provider>
  )
}

export const ArticlesConsumer = ArticlesContext.Consumer
