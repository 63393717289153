import { Dispatch } from 'react'
import Toast from 'react-native-toast-message'
import { TagsActions } from '.'
import { ITag } from '../../api/tags'
import { fetchTags, IFetchTagsParams } from '../../api/tags/tags.query'
import { contentTags } from '../../constants/Config'

const getContentTagsTitles = (tags: ITag[]) => {
  if (!tags.length) return ''
  return tags.reduce((prevValue: ITag, currentValue: ITag) => {
    prevValue.Title = prevValue.Title.concat(', ', currentValue.Title)
    return prevValue
  }).Title
}

const getDifference = (current: ITag[], prev: ITag[]) => {
  return current.filter((currentTag) => {
    return !prev.find((prevTag) => prevTag.Value === currentTag.Value)
  })
}

const checkContentTagsWithLocalStorage = (currentContentTags: ITag[]) => {
  const oldContentTags =
    JSON.parse(localStorage.getItem(contentTags) || 'null') ||
    currentContentTags
  const contentTagsDifference = getDifference(
    currentContentTags,
    oldContentTags
  )
  localStorage.setItem(contentTags, JSON.stringify(currentContentTags))
  if (contentTagsDifference.length) {
    const text1Versions =
      contentTagsDifference.length > 1
        ? 'kategóriák érhetők'
        : 'kategória érhető'
    Toast.hide()
    Toast.show({
      type: 'info',
      text1: `Új ${text1Versions} el!`,
      text2: getContentTagsTitles(contentTagsDifference),
      visibilityTime: 6000,
    })
  }
}

export const useFetchTags = (dispatch: Dispatch<TagsActions>) => {
  return async (params: IFetchTagsParams) => {
    try {
      dispatch({ type: 'TAGS_SET_LOADING', payload: true })
      const tagSet = new Set<string>()
      const response = await fetchTags(params)
      if (!response) throw Error('No payload for fetch tags')
      for (let tag of response) {
        if (tag.Value) {
          tagSet.add(tag.Value)
        }
      }
      const payload = [...tagSet.values()].map(
        (value) => response.find((tag) => tag.Value === value) as ITag
      )
      dispatch({ type: 'TAGS_LOAD_SUCCESS', payload })
      dispatch({ type: 'TAGS_SET_LOADING', payload: false })
      if (params.TagType === 'content') {
        checkContentTagsWithLocalStorage(payload)
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: 'TAGS_SET_LOADING', payload: false })
    }
  }
}
