import React, { useEffect, useState } from 'react'
import { Pressable, StyleSheet, View, Linking } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { Text } from 'react-native-elements'

import Colors from '../constants/Colors'
import { getPDFUri } from '../screens/PDFReaderScreen'
import { IAnalysisSource } from '../api/analyses'
import { DropdownList } from './DropdownList'
import { NavbarIconButton, navbarIcons } from './NavbarIconButton'

import {
  NativeStackHeaderProps,
  NativeStackNavigationProp,
} from '@react-navigation/native-stack'
import {
  BottomTabHeaderProps,
  BottomTabNavigationProp,
} from '@react-navigation/bottom-tabs'
import { ParamListBase, Route, RouteProp } from '@react-navigation/native'

const titles: Record<string, string> = {
  Root: '',
  Favorites: 'Kedvencek',
  Search: '',
  AnalysisRoot: 'Elemzések',
  AnalysisSearch: 'Keresés',
  AnalysisFavorites: 'Kedvencek',
  Settings: 'Beállítások',
}

const setActiveState = (
  routeName: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
) => {
  if (
    routeName === 'AnalysisRoot' ||
    routeName === 'AnalysisSearch' ||
    routeName === 'AnalysisFavorites'
  ) {
    setActive('Analysis')
  } else if (
    routeName === 'Root' ||
    routeName === 'Search' ||
    routeName === 'Favorites'
  ) {
    setActive('Home')
  } else {
    setActive(routeName)
  }
}

const setIsPDFScreenState = (
  routeName: string,
  setIsPDFScreen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (routeName === 'PDFScreen') {
    setIsPDFScreen(true)
  } else {
    setIsPDFScreen(false)
  }
}

const setIsDropdownVisibleState = (
  routeName: string,
  setIsDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (routeName === 'Root' || routeName === 'Search') {
    setIsDropdownVisible(true)
  } else {
    setIsDropdownVisible(false)
  }
}

const setIsCanGoBackState = (
  routeName: string,
  setIsCanGoBack: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (routeName in titles) {
    setIsCanGoBack(false)
  } else {
    setIsCanGoBack(true)
  }
}

const iconHeaderSide = (
  navigation:
    | BottomTabNavigationProp<ParamListBase, string>
    | NativeStackNavigationProp<ParamListBase, string>,
  active: string,
  side: keyof typeof navbarIcons
) => (
  <View style={styles.wideSide}>
    <NavbarIconButton navigation={navigation} active={active} side={side} />
  </View>
)

const leftHeaderSideCanGoBack = (
  navigation:
    | BottomTabNavigationProp<ParamListBase, string>
    | NativeStackNavigationProp<ParamListBase, string>
) => (
  <Pressable
    onPress={() => {
      navigation.goBack()
    }}
    style={styles.narrowSide}
  >
    <Icon
      name={'arrow-back-ios'}
      size={18}
      color={Colors.orangeWeb}
      style={styles.icon}
    />
  </Pressable>
)

const rightHeaderSideCanGoBack = (
  route: Route<string, object | undefined> | RouteProp<ParamListBase, string>,
  isPDFScreen: boolean
) =>
  isPDFScreen ? (
    <Pressable
      onPress={() => {
        Linking.openURL(getPDFUri((route?.params as IAnalysisSource).Uri))
      }}
      style={styles.narrowSide}
    >
      <Icon
        name={'save'}
        size={26}
        color={Colors.orangeWeb}
        style={styles.icon}
      />
    </Pressable>
  ) : (
    <View style={styles.narrowSide}></View>
  )

export const HeaderComponent: React.FC<
  NativeStackHeaderProps | BottomTabHeaderProps
> = (props) => {
  const { navigation, route, options } = props
  if (!navigation.canGoBack) return null
  const SourceName = (route?.params as ParamListBase)?.SourceName
  const [active, setActive] = useState('')
  const [isCanGoBack, setIsCanGoBack] = useState(false)
  const [isPDFScreen, setIsPDFScreen] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  useEffect(() => {
    setActiveState(route.name, setActive)
    setIsPDFScreenState(route.name, setIsPDFScreen)
    setIsDropdownVisibleState(route.name, setIsDropdownVisible)
    setIsCanGoBackState(route.name, setIsCanGoBack)
  }, [route])

  return (
    <View style={styles.screenOptionsHeader}>
      {isCanGoBack
        ? leftHeaderSideCanGoBack(navigation)
        : iconHeaderSide(navigation, active, 'left')}
      <View style={styles.view}>
        <Text style={styles.screenOptionsHeaderTitle}>
          {isDropdownVisible ? (
            <DropdownList navigation={navigation} />
          ) : (
            titles[route.name] || SourceName || options.headerTitle || ''
          )}
        </Text>
      </View>
      {isCanGoBack
        ? rightHeaderSideCanGoBack(route, isPDFScreen)
        : iconHeaderSide(navigation, active, 'right')}
    </View>
  )
}

const styles = StyleSheet.create({
  screenOptionsHeader: {
    height: 50,
    minHeight: 50,
    maxHeight: 50,
    backgroundColor: Colors.oxfordBlue,
    alignItems: 'center',
    flexDirection: 'row',
  },
  screenOptionsHeaderTitle: {
    color: Colors.orangeWeb,
    fontFamily: 'Roboto_500Medium',
    fontSize: 18,
    textAlign: 'center',
  },
  view: { flex: 1 },
  icon: {
    marginLeft: 13,
    marginRight: 6,
  },
  narrowSide: { minWidth: 50 },
  wideSide: { minWidth: 80 },
})
