import { authTokenPath, refreshTokenPath } from '../../constants/Config'
import { UserActions } from './user.actions'
import { IUserState } from './user.state'

export const userReducer = (
  state: IUserState,
  action: UserActions
): IUserState => {
  switch (action.type) {
    case 'PROFILE_LOAD_SUCCESS':
      return {
        ...state,
        user: action.payload,
      }
    case 'UPDATE_BOOKMARKS':
      return {
        ...state,
        user: {
          ...state.user,
          BookmarkedNews: action.payload,
        },
      }
    case 'UPDATE_ANALYSES_BOOKMARKS':
      return {
        ...state,
        user: {
          ...state.user,
          BookmarkedAnalyses: action.payload,
        },
      }
    case 'UPDATE_PREFERRED_TAGS':
      return {
        ...state,
        user: {
          ...state.user,
          PreferredTags: action.payload,
        },
      }
    case 'UPDATE_PREFERRED_LANGUAGE':
      return {
        ...state,
        user: {
          ...state.user,
          PreferredLanguage: action.payload,
        },
      }
    case 'UPDATE_SNOOZE_TIME':
      return {
        ...state,
      }
    case 'UPDATE_DID_UNSUBSCRIBE':
      return {
        ...state,
        user: {
          ...state.user,
          DidUnsubscribe: action.payload,
        },
      }
    case 'SET_TOKEN': {
      if (!action.payload) {
        localStorage.removeItem(authTokenPath)
        return {
          ...state,
          token: undefined,
        }
      }
      localStorage.setItem(authTokenPath, action.payload)
      return {
        ...state,
        token: action.payload,
      }
    }
    case 'SET_REFRESH_TOKEN': {
      if (!action.payload) {
        localStorage.removeItem(refreshTokenPath)
        return {
          ...state,
          refreshToken: undefined,
        }
      }
      localStorage.setItem(refreshTokenPath, action.payload)
      return {
        ...state,
        refreshToken: action.payload,
      }
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'PROFILE_SET_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}
