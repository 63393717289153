import React from 'react'
import { FunctionComponent } from 'react'
import { Text, View, StyleSheet, Pressable } from 'react-native'
import { Card } from 'react-native-elements'

import Colors from '../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { SentimentIcon } from './SentimentIcon'
import { IArticle } from '../api/articles'
import { UserConsumer } from '../context'
import { DateConverter } from './DateConverter'
import { path } from '../utils'
import { apiUrl, httpRegexp } from '../constants/Config'

import { ArticleTypes } from './ListComponent'
import { ArticleTileSourceComponent } from './articleTileSourceComponent'

export interface ITileProps {
  article: IArticle
  articleType: ArticleTypes
}

export const getImageUri = (uri: string): string => {
  uri = uri || ''
  return httpRegexp.test(uri) ? uri : path.join([apiUrl, uri])
}

export const Tile: FunctionComponent<ITileProps> = (props) => {
  const { article, articleType } = props
  return (
    <UserConsumer>
      {({ user, setBookmarks }) => {
        const index = user.BookmarkedNews.indexOf(article.ArticleId)
        const isBookmarked = index > -1
        return (
          <Card containerStyle={styles.cardContainer}>
            <Card.Image
              style={styles.image}
              containerStyle={styles.imageContainer}
              placeholderStyle={styles.imagePlaceholder}
              source={{ uri: getImageUri(article.Thumbnail) }}
              resizeMode={'cover'}
            ></Card.Image>
            {articleType === ArticleTypes.Video && (
              <View style={styles.imageShadow}>
                <View style={styles.playIconWrap}>
                  <Icon
                    name="play-arrow"
                    color={Colors.orangeWeb}
                    style={styles.playIcon}
                    size={30}
                  />
                </View>
              </View>
            )}
            <View style={styles.cardInfoWrapper}>
              <View style={styles.titleFavoriteContainer}>
                <Text style={styles.tileTitle} numberOfLines={3}>
                  {article.Title}
                </Text>
                <Pressable
                  style={styles.iconWrapper}
                  onPress={() => {
                    if (index === -1) {
                      setBookmarks(
                        [...user.BookmarkedNews, article.ArticleId],
                        user.BookmarkedAnalyses
                      )
                    } else {
                      const tags = [...user.BookmarkedNews]
                      tags.splice(index, 1)
                      setBookmarks(tags, user.BookmarkedAnalyses)
                    }
                  }}
                >
                  <Icon
                    name={isBookmarked ? 'bookmark' : 'bookmark-border'}
                    size={24}
                    style={styles.favoriteIcon}
                  />
                </Pressable>
              </View>
              <View style={styles.sourceSentimentContainer}>
                <Text style={styles.source} numberOfLines={1}>
                  <ArticleTileSourceComponent
                    articleType={articleType}
                    article={article}
                  />
                  <Text>
                    {article.Author && `${article.Author} - `}
                    {DateConverter(article.Date)}
                  </Text>
                </Text>
                <View style={styles.iconWrapper}>
                  <SentimentIcon sentimentTag={article.SentimentTag} />
                </View>
              </View>
            </View>
          </Card>
        )
      }}
    </UserConsumer>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    padding: 0,
    borderRadius: 10,
    margin: 13,
  },
  imageContainer: {
    width: '100%',
    height: '25vh',
    backgroundColor: '#bbbbbb',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  imagePlaceholder: {
    backgroundColor: 'transparent',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  imageShadow: {
    width: '100%',
    height: '25vh',
    backgroundColor: Colors.unselectedBlue + 85,
    position: 'absolute',
    justifyContent: 'center',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  playIconWrap: {
    alignSelf: 'center',
    height: 50,
    width: 50,
    borderRadius: 200,
    backgroundColor: Colors.oxfordBlue + 90,
    justifyContent: 'center',
  },
  playIcon: {
    alignSelf: 'center',
  },
  cardInfoWrapper: {
    margin: 8,
  },
  titleFavoriteContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  tileTitle: {
    width: '85%',
    fontFamily: 'Roboto_500Medium',
    fontSize: 17,
    color: Colors.oxfordBlue,
    paddingBottom: 3,
  },
  favoriteIcon: {
    color: Colors.oxfordBlue,
  },
  iconWrapper: {
    width: '15%',
    alignItems: 'flex-end',
  },
  sourceSentimentContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 12,
    maxHeight: 15,
  },
  source: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_300Light',
    fontSize: 11,
    width: '85%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
})
