import React, { FC } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import Colors from '../constants/Colors'
import { UserConsumer } from '../context'
import { RootStackScreenProps } from '../types'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'
import { Spinner } from '../components/Spinner'
import { ScreenSizeConsumer } from '../context/screen-size.context'

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.white,
  },
  view: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '0px',
  },
  viewSplit: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.platinum,
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginHorizontal: 13,
  },
  radio: {
    marginHorizontal: 13,
  },
})

const onPress = (value: any, setPreferredLanguage: (arg0: string) => void) => {
  setPreferredLanguage(value)
}

const radioButtonsData = [
  {
    id: 'default',
    label: 'Eredeti',
    value: 'default',
  },
  {
    id: 'en',
    label: 'Angol',
    value: 'en',
  },
]

export const PreferredLanguageScreen: FC<
  RootStackScreenProps<'PreferredLanguage'>
> = () => {
  return (
    <ScreenSizeConsumer>
      {({ height }) => (
        <UserConsumer>
          {({ user, setPreferredLanguage, isLoading }) => (
            <View style={[styles.containerView, { height: height - 50 }]}>
              <Spinner isLoading={isLoading}></Spinner>
              <RadioForm formHorizontal={false} animation={true} initial={0}>
                {radioButtonsData.map((data, i) => (
                  <Pressable
                    style={[
                      i < radioButtonsData.length - 1 && styles.viewSplit,
                    ]}
                    key={i + 'pressable'}
                    onPress={() => onPress(data.value, setPreferredLanguage)}
                  >
                    <RadioButton
                      labelHorizontal={true}
                      key={i + 'group'}
                      style={styles.view}
                    >
                      <RadioButtonLabel
                        obj={data}
                        index={i}
                        key={i + 'button'}
                        labelHorizontal={true}
                        onPress={(value) =>
                          onPress(value, setPreferredLanguage)
                        }
                        labelStyle={styles.text}
                      />
                      <RadioButtonInput
                        obj={data}
                        index={i}
                        key={i + 'input'}
                        isSelected={
                          (user.PreferredLanguage || 'default') === data.value
                        }
                        onPress={(value) =>
                          onPress(value, setPreferredLanguage)
                        }
                        buttonInnerColor={Colors.oxfordBlue}
                        buttonOuterColor={Colors.oxfordBlue}
                        buttonSize={8}
                        buttonOuterSize={16}
                        buttonWrapStyle={styles.radio}
                      />
                    </RadioButton>
                  </Pressable>
                ))}
              </RadioForm>
            </View>
          )}
        </UserConsumer>
      )}
    </ScreenSizeConsumer>
  )
}
