import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { CompositeNavigationProp } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { FlatList, Linking, Pressable, StyleSheet, Text } from 'react-native'
import { IArticle } from '../api/articles'
import { IUser } from '../api/user'
import { RefreshControl } from '../components/RefreshControl'
import { Tile } from '../components/Tile'
import Browser from '../constants/Browser'
import Colors from '../constants/Colors'
import { refreshDelayTime } from '../constants/Config'
import { ArticlesConsumer, ArticlesProvider, UserConsumer } from '../context'
import { PreferredTagsContext } from '../context/preferred-tags.context'
import { ScreenSizeConsumer } from '../context/screen-size.context'
import { ScrollToTopConsumer } from '../context/scroll-to-top.context'
import { RootStackParamList, ArticleTabParamList } from '../types'
import { now } from '../utils'

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.listWhite,
  },
  flatList: {
    backgroundColor: Colors.listWhite,
    alignItems: 'center',
    justifyContent: 'center',
  },
  empty: { fontWeight: 'bold', marginVertical: 20, color: 'gray' },
})

export interface IListComponentProps {
  ContentTag?: string
  SourceTag?: string
  SearchText?: string
  ArticleIds?: Array<string>
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<ArticleTabParamList, 'ArticleList'>,
    NativeStackNavigationProp<RootStackParamList, 'List'>
  >
}

export enum ArticleTypes {
  Video,
  Facebook,
  LinkedIn,
  Twitter,
  Instagram,
  TikTok,
  URL,
}

const getArticleType = (item: IArticle) => {
  if (item.VideoUrl) {
    return ArticleTypes.Video
  } else if (item.FacebookArticleUrl) {
    return ArticleTypes.Facebook
  } else if (item.LinkedInArticleUrl) {
    return ArticleTypes.LinkedIn
  } else if (item.TwitterArticleUrl) {
    return ArticleTypes.Twitter
  } else if (item.InstagramArticleUrl) {
    return ArticleTypes.Instagram
  } else if (item.TikTokArticleUrl) {
    return ArticleTypes.TikTok
  }
  return ArticleTypes.URL
}

const getTranslatedURL = (url: string, item: IArticle, lang: string) => {
  const gTranslatedURL = `http://translate.google.com/translate?hl=en&ie=UTF-8&u=${url}&sl=auto&tl=${lang}`
  switch (getArticleType(item)) {
    case ArticleTypes.Facebook: {
      const local = lang === 'en' ? 'US' : lang.toUpperCase()
      return `${url}?locale=${lang}_${local}`
    }
    case ArticleTypes.Instagram: {
      const local = lang === 'en' ? 'US' : lang.toUpperCase()
      return `${url}?locale=${lang}_${local}`
    }
    case ArticleTypes.Twitter:
      return `${url}?lang=${lang}`
    case ArticleTypes.TikTok:
      return `${url}?lang=${lang}`
    case ArticleTypes.Video: {
      if (url.includes('youtube.com/watch?v=')) {
        const newURL = url.replace('watch?v=', 'embed/')
        return `${newURL}?&hl=${lang}&cc_lang_pref=${lang}&cc_load_policy=1`
      }
      return gTranslatedURL
    }
    default: {
      return gTranslatedURL
    }
  }
}

const onPress = async (item: IArticle, user: IUser) => {
  /**
   * user does not need translation,
   * if the preferredLanguage is not set or default,
   * or the source language is the same
   * or the link is a linkedin link
   */
  const needTranslation = !(
    (user?.PreferredLanguage || 'default') === 'default' ||
    item.SourceLanguage === user.PreferredLanguage ||
    getArticleType(item) === ArticleTypes.LinkedIn
  )

  let url =
    item.VideoUrl ||
    item.FacebookArticleUrl ||
    item.LinkedInArticleUrl ||
    item.TwitterArticleUrl ||
    item.InstagramArticleUrl ||
    item.TikTokArticleUrl ||
    item.Url

  if (needTranslation) {
    url = getTranslatedURL(url, item, user.PreferredLanguage)
  }

  try {
    return await Linking.openURL(url)
  } catch (err) {
    return console.error('An error occurred', err)
  }
}

export const ListComponent: React.FC<IListComponentProps> = ({
  ContentTag,
  SourceTag,
  SearchText,
  ArticleIds,
}) => {
  const tags = useContext(PreferredTagsContext)
  const [CreatedBefore, setCreatedBefore] = useState<string>()
  const [isRefreshing, setRefreshing] = useState<boolean>(false)
  const flatlistRef = useRef<FlatList>(null)

  useEffect(() => {
    setCreatedBefore(now().toISOString())
  }, [tags])

  return (
    <ScrollToTopConsumer>
      {({ ScrollToTop }) => {
        if (ScrollToTop && flatlistRef.current?.props.data?.length) {
          flatlistRef.current?.scrollToIndex({ index: 0 })
        }
        return (
          <ScreenSizeConsumer>
            {({ width, height }) => (
              <UserConsumer>
                {({ user }) => (
                  <ArticlesProvider
                    ContentTag={ContentTag}
                    SourceTag={SourceTag}
                    SearchText={SearchText}
                    ArticleIds={ArticleIds}
                    CreatedBefore={CreatedBefore}
                  >
                    <ArticlesConsumer>
                      {({ articles, isLoading, total, setCount }) => {
                        return (
                          <FlatList
                            ref={flatlistRef}
                            contentContainerStyle={styles.flatList}
                            style={[
                              styles.containerView,
                              { height: height - 50 },
                            ]}
                            data={articles}
                            keyExtractor={(article) => `${article.ArticleId}`}
                            onEndReached={() => {
                              if (!articles.length || total <= articles.length)
                                return

                              setCount(articles.length)
                            }}
                            onEndReachedThreshold={0.5}
                            refreshControl={
                              <RefreshControl
                                refreshing={isRefreshing || isLoading}
                                size="large"
                                tintColor={Colors.orangeWeb}
                                onRefresh={() => {
                                  setRefreshing(true)
                                  setTimeout(() => {
                                    setCreatedBefore(now().toISOString())
                                    setRefreshing(false)
                                  }, refreshDelayTime)
                                }}
                              />
                            }
                            renderItem={({ item }) => (
                              <>
                                <Pressable
                                  onPress={() => onPress(item, user)}
                                  style={({ pressed }) => ({
                                    opacity: pressed ? 0.5 : 1,
                                    flex: 1,
                                    width,
                                    ...(!Browser.isMobile &&
                                      !Browser.isIOS && {
                                        paddingHorizontal: 15,
                                      }),
                                  })}
                                >
                                  <Tile
                                    article={item}
                                    articleType={getArticleType(item)}
                                  />
                                </Pressable>
                                {total === articles.indexOf(item) + 1 && (
                                  <div
                                    style={{
                                      background: 'red',
                                      marginBottom: 75,
                                    }}
                                  ></div>
                                )}
                              </>
                            )}
                            ListEmptyComponent={
                              isLoading ? null : (
                                <Text style={styles.empty}>
                                  Nincs a keresési feltételeknek megfelelő cikk
                                </Text>
                              )
                            }
                          />
                        )
                      }}
                    </ArticlesConsumer>
                  </ArticlesProvider>
                )}
              </UserConsumer>
            )}
          </ScreenSizeConsumer>
        )
      }}
    </ScrollToTopConsumer>
  )
}
