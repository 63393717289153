import React, { FunctionComponent, useEffect, useState } from 'react'
import { Dropdown } from 'react-native-element-dropdown'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { View, Text, StyleSheet } from 'react-native'
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'

import Colors from '../constants/Colors'
import { ITag } from '../api/tags/tags.types'
import { SourceTagsConsumer } from '../context'
import { ParamListBase } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

export interface IDropdownListProps {
  navigation:
    | BottomTabNavigationProp<ParamListBase, string>
    | NativeStackNavigationProp<ParamListBase, string>
}

const ALL_SOURCES = 'Minden forrás'
const LINE_HEIGHT = 33
const MAX_LINES = 10

const styles = StyleSheet.create({
  dropdown: {
    height: 24,
    width: 170,
    marginRight: 13,
  },
  placeholder: {
    color: Colors.orangeWeb,
    fontSize: 16,
    marginLeft: 8,
    marginRight: 8,
  },
  dropdownContainer: {
    alignSelf: 'flex-start',
    width: 146,
    borderColor: Colors.oxfordBlue,
    borderBottomStartRadius: 10,
    borderBottomEndRadius: 10,
  },
  dropdownContainerTextWrap: {
    flex: 1,
    flexDirection: 'row',
    height: 25,
    margin: 8,
  },
  dropdownContainerText: {
    color: Colors.oxfordBlue,
    fontSize: 14,
  },
  selected: {
    fontFamily: 'Roboto_400Regular',
    width: '90%',
  },
  unselected: {
    fontFamily: 'Roboto_300Light',
  },
})

const EmptySourceTag: ITag = {
  Type: 'source',
  Value: '',
  Title: 'Minden forrás',
  HasArticles: true,
  HasAnalyses: false,
}

const renderRightIcon = () => (
  <Icon name={'expand-more'} size={24} color={Colors.orangeWeb} />
)

export const DropdownList: FunctionComponent<IDropdownListProps> = ({
  navigation,
}) => {
  const [selected, setSelected] = useState<string>('')

  useEffect(() => {
    navigation.setParams({ SourceTag: selected })
  }, [selected])

  const renderItem = (item: ITag) => {
    const isSelected = selected === item.Value
    return (
      <View style={styles.dropdownContainerTextWrap}>
        <Text
          numberOfLines={1}
          style={[
            styles.dropdownContainerText,
            isSelected ? styles.selected : styles.unselected,
          ]}
        >
          {item.Title}
        </Text>
        {isSelected && <Icon name="check" size={16} />}
      </View>
    )
  }

  const onChange = (item: ITag) => {
    setSelected(selected === item.Value ? '' : item.Value)
  }

  return (
    <SourceTagsConsumer>
      {(sourceTags) => (
        <View>
          <Dropdown
            data={[EmptySourceTag, ...sourceTags]}
            placeholder={ALL_SOURCES}
            labelField="Title"
            valueField="Value"
            value={selected}
            onChange={onChange}
            renderRightIcon={renderRightIcon}
            renderItem={renderItem}
            style={styles.dropdown}
            maxHeight={
              Math.min((sourceTags?.length || 0) + 1, MAX_LINES) * LINE_HEIGHT
            }
            showsVerticalScrollIndicator={false}
            fontFamily="Roboto_400Regular"
            placeholderStyle={styles.placeholder}
            selectedTextStyle={styles.placeholder}
            selectedTextProps={{ numberOfLines: 1 }}
            containerStyle={styles.dropdownContainer}
            activeColor={Colors.unselectedBlue + 20}
            autoScroll={false}
          />
        </View>
      )}
    </SourceTagsConsumer>
  )
}
