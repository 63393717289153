import React, { FC, useContext, useState } from 'react'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'
import ProfileDeleteModal from '../components/ProfileDeleteModal'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import JSON from '../package.json'
import { RootStackScreenProps } from '../types'
import { Spinner } from '../components/Spinner'
import ConfirmationModalModal from '../components/ConfirmationModal'
import { ScreenSizeConsumer } from '../context/screen-size.context'

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.white,
  },
  headerView: {
    height: 40,
    backgroundColor: Colors.platinum,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  headerText: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_500Medium',
    fontSize: 17,
    marginHorizontal: 13,
  },
  view: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  viewSplit: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.platinum,
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginHorizontal: 13,
  },
  icon: {
    marginHorizontal: 13,
  },
  info: {
    marginHorizontal: 13,
    fontSize: 14,
    fontFamily: 'Roboto_300Light',
    color: Colors.oxfordBlue,
  },
})

export const SettingsScreen: FC<RootStackScreenProps<'Settings'>> = (props) => {
  const { navigation } = props
  const { user, isLoading, logout } = useContext(UserContext)
  const [logoutModalVisible, setLogoutModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  return (
    <ScreenSizeConsumer>
      {({ height }) => (
        <View style={[styles.containerView, { height: height - 50 }]}>
          <Spinner isLoading={isLoading}></Spinner>
          <Pressable
            style={[styles.view, styles.viewSplit]}
            onPress={() => navigation.navigate('Categories')}
          >
            <Text style={styles.text}>Kategóriák</Text>
            <Icon
              name={'arrow-forward-ios'}
              size={16}
              color={Colors.oxfordBlue}
              onPress={() => navigation.navigate('Categories')}
              style={styles.icon}
            />
          </Pressable>
          <Pressable
            style={[styles.view, styles.viewSplit]}
            onPress={() => navigation.navigate('PreferredLanguage')}
          >
            <Text style={styles.text}>Cikkek elsődleges nyelve</Text>
            <Icon
              name={'arrow-forward-ios'}
              size={16}
              color={Colors.oxfordBlue}
              onPress={() => navigation.navigate('PreferredLanguage')}
              style={styles.icon}
            />
          </Pressable>
          <Pressable
            style={[styles.view, styles.viewSplit]}
            onPress={() => navigation.navigate('Notification')}
          >
            <Text style={styles.text}>Értesítések</Text>
            <Icon
              name={'arrow-forward-ios'}
              size={16}
              color={Colors.oxfordBlue}
              onPress={() => navigation.navigate('Notification')}
              style={styles.icon}
            />
          </Pressable>
          <View style={[styles.view, styles.viewSplit]}>
            <Text style={styles.text}>Verzió</Text>
            <Text style={styles.info}>v{JSON.version}</Text>
          </View>
          <View style={[styles.view, styles.viewSplit]}>
            <Text style={styles.text}>E-mail</Text>
            <Text style={styles.info}>{user?.Email}</Text>
          </View>
          <Pressable
            style={[styles.view, styles.viewSplit]}
            onPress={() => {
              setDeleteModalVisible(true)
            }}
          >
            <Text style={styles.text}>Előfizetés törlése</Text>
            <Icon
              name="delete"
              size={16}
              color={Colors.oxfordBlue}
              style={styles.icon}
            />
          </Pressable>
          <Pressable
            style={[styles.view]}
            onPress={() => setLogoutModalVisible(true)}
          >
            <Text style={styles.text}>Kijelentkezés</Text>
            <Icon
              name="logout"
              size={16}
              color={Colors.oxfordBlue}
              style={styles.icon}
            />
          </Pressable>
          <ProfileDeleteModal
            modalVisible={deleteModalVisible}
            setModalVisible={setDeleteModalVisible}
          />
          <ConfirmationModalModal
            isVisible={logoutModalVisible}
            setVisible={setLogoutModalVisible}
            onSubmit={() => logout()}
            text="Biztosan ki szeretnél jelentkezni?"
          />
        </View>
      )}
    </ScreenSizeConsumer>
  )
}
