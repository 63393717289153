import React from 'react'
import { FunctionComponent } from 'react'
import { Text, StyleSheet } from 'react-native'

import Colors from '../constants/Colors'
import { IArticle } from '../api/articles'

import { FontAwesome5 } from '@expo/vector-icons'
import { ArticleTypes } from './ListComponent'
import { Defs, RadialGradient, Rect, Stop, Svg } from 'react-native-svg'

export interface ITileProps {
  article: IArticle
  articleType: ArticleTypes
}

const setSource = (
  articleType: ArticleTypes,
  article: IArticle
): {
  pageName: string
  iconName?: string
  iconStyle?:
    | typeof styles[keyof typeof styles]
    | typeof styles[keyof typeof styles][]
  backgroundColor?: string
} => {
  let pageName = article.SourceTag?.Title ? article.SourceTag.Title : 'Unknown'
  switch (articleType) {
    case ArticleTypes.Video:
      if (article.VideoUrl.includes('youtube.com'))
        return {
          pageName: 'Youtube',
          iconName: 'youtube',
          iconStyle: styles.youtubeIcon,
          backgroundColor: 'transparent',
        }
      return {
        pageName,
        iconName: 'video',
        iconStyle: styles.videoIcon,
        backgroundColor: 'transparent',
      }
    case ArticleTypes.Facebook:
      return {
        pageName: 'Facebook',
        iconName: 'facebook-f',
        iconStyle: [styles.facebookIcon, styles.icon12px],
        backgroundColor: '#4267B2',
      }
    case ArticleTypes.LinkedIn:
      return {
        pageName: 'LinkedIn',
        iconName: 'linkedin-in',
        iconStyle: styles.icon10px,
        backgroundColor: '#0077B5',
      }
    case ArticleTypes.Twitter:
      return {
        pageName: 'Twitter',
        iconName: 'twitter',
        iconStyle: styles.icon10px,
        backgroundColor: '#1DA1F2',
      }
    case ArticleTypes.Instagram:
      return {
        pageName: 'Instagram',
        iconName: 'instagram',
        iconStyle: styles.icon12px,
      }
    case ArticleTypes.TikTok:
      return {
        pageName: 'TikTok',
        iconName: 'tiktok',
        iconStyle: [styles.tiktokIcon, styles.icon10px],
        backgroundColor: '#111',
      }
    default:
      return { pageName, backgroundColor: 'transparent' }
  }
}

export const ArticleTileSourceComponent: FunctionComponent<ITileProps> = (
  props
) => {
  const { article, articleType } = props
  const { iconName, iconStyle, pageName, backgroundColor } = setSource(
    articleType,
    article
  )
  return (
    <Text style={styles.iconSourceContainer}>
      <Svg width="15" height="15" viewBox="0 0 15 15" style={styles.svgStyle}>
        <Defs>
          <RadialGradient
            id="grad"
            cx="11"
            cy="9"
            fx="4.2"
            fy="16"
            r="15"
            gradientUnits="userSpaceOnUse"
          >
            <Stop offset="0" stopColor="#fdf497" />
            <Stop offset="0.05" stopColor="#fdf497" />
            <Stop offset="0.45" stopColor="#fd5949" />
            <Stop offset="0.6" stopColor="#d6249f" />
            <Stop offset="0.9" stopColor="#285AEB" />
          </RadialGradient>
        </Defs>
        <Rect
          width="100%"
          height="100%"
          fill={backgroundColor || 'url(#grad)'}
        />
      </Svg>
      <FontAwesome5 name={iconName} style={iconStyle} />
      <Text>
        {pageName} -{'\u00a0'}
      </Text>
    </Text>
  )
}

const styles = StyleSheet.create({
  svgStyle: {
    flex: 1,
    borderRadius: 4,
    marginRight: 5,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    elevation: -1,
  },
  iconSourceContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  facebookIcon: {
    paddingTop: 2,
  },
  icon10px: {
    fontSize: '10px',
    marginRight: '5px',
    height: '10px',
    width: '15px',
    color: Colors.white,
  },
  icon12px: {
    fontSize: '12px',
    color: Colors.white,
    height: '12px',
    width: '15px',
    marginRight: '5px',
  },
  tiktokIcon: {
    paddingTop: 0.4,
    filter:
      'drop-shadow(1px 1px 0px #FD3E3E) drop-shadow(-0.3px -0.3px 0px #4DE8F4)',
  },
  youtubeIcon: {
    fontSize: '14px',
    height: '14px',
    width: '15px',
    marginRight: '5px',
    color: '#FF0000',
  },
  videoIcon: {
    fontSize: '13px',
    marginRight: '5px',
    height: '13px',
    width: '15px',
    color: Colors.oxfordBlue,
  },
})
