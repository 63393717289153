import React, { useEffect, useState, useContext, useRef } from 'react'
import { Text, StyleSheet, Pressable, FlatList } from 'react-native'
import Colors from '../constants/Colors'
import { RootStackParamList, AnalysisTabParamList } from '../types'
import { now } from '../utils'
import { refreshDelayTime } from '../constants/Config'
import { AnalysesConsumer, AnalysesProvider } from '../context/analyses.context'
import { RefreshControl } from './RefreshControl'
import { ScreenSizeConsumer } from '../context/screen-size.context'
import Browser from '../constants/Browser'
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { CompositeNavigationProp } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import CommentingModal from './CommentingModal'
import { AnalysisTile } from './AnalysisTile'
import { PreferredTagsContext } from '../context/preferred-tags.context'
import { ScrollToTopConsumer } from '../context/scroll-to-top.context'

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.listWhite,
  },
  flatList: {
    backgroundColor: Colors.listWhite,
    alignItems: 'center',
    justifyContent: 'center',
  },
  empty: {
    fontWeight: 'bold',
    marginVertical: 20,
    color: 'gray',
  },
})

export interface IAnalysisListComponentProps {
  ContentTag?: string
  SearchText?: string
  AnalysesIds?: Array<string>
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<AnalysisTabParamList, 'AnalysisList'>,
    NativeStackNavigationProp<RootStackParamList, 'List'>
  >
}

export const AnalysisListComponent: React.FC<IAnalysisListComponentProps> = ({
  ContentTag,
  SearchText,
  AnalysesIds,
  navigation,
}) => {
  const tags = useContext(PreferredTagsContext)
  const [CreatedBefore, setCreatedBefore] = useState<string>(
    now().toISOString()
  )
  const [isRefreshing, setRefreshing] = useState<boolean>(false)
  const [isCommentingModalVisible, setCommentingModalVisible] =
    useState<boolean>(false)
  const [commentedAnalysisId, setCommentedAnalysisId] = useState<string>('')
  const flatlistRef = useRef<FlatList>(null)

  useEffect(() => {
    setCreatedBefore(now().toISOString())
  }, [tags])

  return (
    <ScrollToTopConsumer>
      {({ ScrollToTop }) => {
        if (ScrollToTop && flatlistRef.current?.props.data?.length) {
          flatlistRef.current?.scrollToIndex({ index: 0 })
        }
        return (
          <ScreenSizeConsumer>
            {({ width, height }) => (
              <AnalysesProvider
                ContentTag={ContentTag}
                CreatedBefore={CreatedBefore}
                AnalysesIds={AnalysesIds}
                SearchText={SearchText}
              >
                <AnalysesConsumer>
                  {({ analyses, isLoading, total, setCount, message }) => {
                    return (
                      <>
                        <FlatList
                          ref={flatlistRef}
                          contentContainerStyle={styles.flatList}
                          style={[
                            styles.containerView,
                            { height: height - 50 },
                          ]}
                          data={analyses}
                          keyExtractor={(analysis) => `${analysis.AnalysisId}`}
                          onEndReached={() => {
                            if (!analyses.length || total <= analyses.length)
                              return

                            setCount(analyses.length)
                          }}
                          onEndReachedThreshold={0.5}
                          refreshControl={
                            <RefreshControl
                              refreshing={isRefreshing || isLoading}
                              size="large"
                              tintColor={Colors.orangeWeb}
                              onRefresh={() => {
                                setRefreshing(true)
                                setTimeout(() => {
                                  setCreatedBefore(now().toISOString())
                                  setRefreshing(false)
                                }, refreshDelayTime)
                              }}
                            />
                          }
                          renderItem={({ item }) => {
                            return (
                              <>
                                <Pressable
                                  onPress={() =>
                                    navigation.navigate(
                                      'PDFReader',
                                      item.Source
                                    )
                                  }
                                  style={({ pressed }) => ({
                                    opacity: pressed ? 0.5 : 1,
                                    flex: 1,
                                    width,
                                    ...(!Browser.isMobile &&
                                      !Browser.isIOS && {
                                        paddingHorizontal: 15,
                                      }),
                                  })}
                                >
                                  <AnalysisTile
                                    analysis={item}
                                    setCommentingModalVisible={
                                      setCommentingModalVisible
                                    }
                                    setCommentedAnalysisId={
                                      setCommentedAnalysisId
                                    }
                                    navigation={navigation}
                                  />
                                </Pressable>
                                {total === analyses.indexOf(item) + 1 && (
                                  <div
                                    style={{
                                      background: 'red',
                                      marginBottom: 75,
                                    }}
                                  ></div>
                                )}
                              </>
                            )
                          }}
                          ListEmptyComponent={
                            isLoading ? null : (
                              <Text style={styles.empty}>
                                Nincs a keresési feltételeknek megfelelő elemzés
                              </Text>
                            )
                          }
                        />
                        <CommentingModal
                          isVisible={isCommentingModalVisible}
                          setVisible={setCommentingModalVisible}
                          id={commentedAnalysisId}
                          setId={setCommentedAnalysisId}
                          onSubmit={message}
                        />
                      </>
                    )
                  }}
                </AnalysesConsumer>
              </AnalysesProvider>
            )}
          </ScreenSizeConsumer>
        )
      }}
    </ScrollToTopConsumer>
  )
}
